<template>
  <div>
    <!-- Loading -->
    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Kuis</span>
    </div>

    <!-- Quiz Section -->
    <div v-if="!isLoading">
      <!-- Quiz Submission Card Header -->
      <b-card
        bg-variant="transparent"
        class="material-card border-light-secondary mb-1"
      >
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              rounded="lg"
              :variant="taskType(quizSession.quiz_overview.quiz_type).variant"
            >
              <i
                class="card-icon"
                :class="taskType(quizSession.quiz_overview.quiz_type).icon"
              />
            </b-avatar>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column ">
                <span class="text-muted ">
                  {{ taskType(quizSession.quiz_overview.quiz_type).title }}
                </span>
                <h6 class="font-weight-bolder small-gap-bottom">
                  {{ quizSession.quiz_overview.quiz_name }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <!-- Multiple Choice -->
      <b-card
        bg-variant="transparent"
        class="material-card border-light-secondary mb-1 p-1"
      >
        <b-row>
          <b-col md="8">
            <!-- Exam Detail -->
            <div class="d-flex justify-content-between flex-wrap mb-2">
              <!-- Teacher Info -->
              <div class="mb-1">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Guru Kelas
                </h6>
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      size="32"
                      :src="quizSession.quiz_overview.quiz_teacher.avatar"
                      :text="
                        avatarText(quizSession.quiz_overview.quiz_teacher.name)
                      "
                      variant="light-primary"
                    />
                  </template>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ quizSession.quiz_overview.quiz_teacher.name }}
                  </span>
                  <small class="text-muted">
                    {{ quizSession.quiz_overview.quiz_teacher.school_name }}
                  </small>
                </b-media>
              </div>

              <!-- Class Info -->
              <div>
                <h6 class="font-weight-bolder small-gap-bottom">
                  Kelas
                </h6>
                <span>
                  {{ quizSession.quiz_overview.quiz_classroom.name }}
                </span>
              </div>
            </div>

            <!-- Exam Content -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Pertanyaan
              </h6>

              <!-- Exam Question -->
              <div class="d-flex mb-1">
                <b-avatar class="small-gap-right" variant="primary">
                  {{ allQuiz[quizIndex].question_number }}
                </b-avatar>
                <p class="font-weight-normal">
                  {{ allQuiz[quizIndex].question }}
                </p>
              </div>

              <h6 class="font-weight-bolder small-gap-bottom">
                Jawaban
              </h6>

              <!-- Exam Answer Multiple Choic-->
              <div
                v-if="allQuiz[quizIndex].exam_question_options.length > 0"
                class="exam-answer-options"
              >
                <b-form-group
                  v-for="option in currentQuiz.exam_question_options"
                  :key="option.id"
                >
                  <b-input-group class="small-gap-bottom">
                    <b-input-group-prepend is-text>
                      <b-form-radio v-model="answer" :value="option.id" plain />
                    </b-input-group-prepend>
                    <b-form-input
                      :value="option.option"
                      readonly
                      @click="createAnswer(option.id)"
                    />
                  </b-input-group>
                </b-form-group>
              </div>

              <!-- Exam Answer Essay -->
              <div
                v-if="allQuiz[quizIndex].exam_question_options.length === 0"
                class="mt-1"
              >
                <b-form-group>
                  <b-input-group class="small-gap-bottom">
                    <quill-editor v-model="allQuiz[quizIndex].answer" />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <!-- Progress Info -->
            <div class="progress-quiz">
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Progress
                </h6>
                <h6 class="font-weight-bolder small-gap-bottom">
                  {{ countdown }}
                </h6>
              </div>
              <b-progress
                class="small-gap-top small-gap-bottom"
                :value="quizProgress"
                max="100"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center small-gap-bottom"
            >
              <h6 class="font-weight-bolder mb-0">
                Nomor Soal
              </h6>

              <!-- Quiz Start Date -->
              <!-- <b-badge variant="light-secondary">
              Senin 10 Oktober 2022, 10:00 WIB
            </b-badge> -->
            </div>

            <!-- Exam Navigation -->
            <div>
              <b-row>
                <b-col>
                  <div class="d-flex flex-wrap">
                    <b-button
                      v-for="(nav, index) in quizNavigations"
                      :key="index"
                      class="round-button text-center"
                      :variant="getQuizNavigationVariant(nav)"
                      size="sm"
                      pill
                      @click="navigateToQuiz(index)"
                    >
                      {{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col class="d-flex justify-content-center flex-wrap">
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom"
                    variant="warning"
                  >
                    Ditandai
                  </b-badge>
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom"
                    variant="secondary"
                  >
                    Dijawab
                  </b-badge>
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom outline-primary"
                    variant="light-danger"
                  >
                    Belum Dijawab
                  </b-badge>
                  <b-badge class="px-1 small-gap-bottom" variant="primary">
                    Sekarang
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <!-- Action Button -->
          <b-col md="12" class="border-top pt-1 mt-1">
            <b-button
              v-show="!quizIndex == 0"
              variant="outline-primary"
              @click="navigateToQuiz(-1, true)"
            >
              <i class="align-middle ti ti-arrow-left" />
            </b-button>
            <b-button
              :variant="isCurrentQuizMarked ? 'warning' : 'outline-warning'"
              class="ml-1"
              @click="markQuiz"
            >
              <i class="align-middle ti ti-star" />
              <span class="align-middle ml-1">
                {{ isCurrentQuizMarked ? 'Ditandai' : 'Tandai' }}
              </span>
            </b-button>
            <b-button
              v-show="quizIndex !== allQuiz.length - 1"
              variant="primary"
              class="ml-1"
              @click="navigateToQuiz(1, true)"
            >
              <span class="align-middle mr-1">Selanjutnya</span>
              <i class="align-middle ti ti-arrow-right" />
            </b-button>
            <b-button
              v-show="quizIndex === allQuiz.length - 1"
              variant="primary"
              class="ml-1"
              @click="submitForm"
            >
              <i class="align-middle ti ti-device-floppy mr-1" />
              <span class="align-middle ">Simpan</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, taskType } from '@core/utils/filter'

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormRadio,
  BInputGroupPrepend,
  BAvatar,
  BMedia,
  BProgress,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BInputGroup,
    BFormInput,
    BFormRadio,
    BInputGroupPrepend,
    BAvatar,
    BMedia,
    BProgress,
    BFormGroup,
    BSpinner,

    quillEditor,
  },
  props: {
    contentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // State
      isLoading: true,

      // Default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      contentType: 'essay',

      // Exam Data
      quizIndex: 0,
      quizLastViewed: 0,
      countIsAnswered: 0,
      quizNavigations: [],
      quizzes: [],
      answer: null,

      // Quiz Data and Session
      quizOverview: {},
      quizSession: {},

      // Timer
      timeDurationOnSecond: 0,
      timePassed: 0,
      timerInterval: null,
      timer: {
        hour: 0,
        minute: 0,
        second: 0,
      },
    }
  },
  // Breadcrumb data
  beforeRouteEnter(to, from, next) {
    const { role, breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  computed: {
    allQuiz() {
      return this.quizzes
    },
    currentQuiz() {
      return this.allQuiz[this.quizIndex]
    },
    isCurrentQuizMarked() {
      return this.quizNavigations[this.quizIndex].isMarked
    },
    quizProgress() {
      return (this.countIsAnswered / this.allQuiz.length) * 100
    },

    // Timer
    timeLeft() {
      return this.timeDurationOnSecond - this.timePassed
    },
    countdown() {
      const hour = Math.floor(this.timeLeft / 3600)
      const minute = Math.floor((this.timeLeft % 3600) / 60)
      const second = this.timeLeft % 60

      return `${hour < 10 ? `0${hour}` : hour}:${
        minute < 10 ? `0${minute}` : minute
      }:${second < 10 ? `0${second}` : second}`
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeDurationOnSecond
      return (
        rawTimeFraction -
        (1 / this.timeDurationOnSecond) * (1 - rawTimeFraction)
      )
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp()
      }
    },
  },

  async mounted() {
    const url = `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/${this.taskId}/get-session`
    await client.get(url).then((response) => {
      const results = response.data.data

      const sessionData = JSON.parse(results.data)
      const examQuestions = sessionData.quiz_overview.quiz_question
      this.contentType = sessionData.quiz_overview.quiz_type

      // sort result by its question number
      examQuestions.sort((a, b) => a.question_number - b.question_number)

      // set quiz session on mounted
      this.quizSession = sessionData

      // filling empty answer to all quiz if no session data
      if (this.quizSession.data.length === 0) {
        examQuestions.map((quiz) => {
          quiz.answer = null

          // set default session answer to null
          this.quizSession.data.push({
            exam_question_id: quiz.id,
            exam_question_option_id: null,
            essay_answer: null,
          })
        })
      }
      this.quizzes = examQuestions

      // Generate Navigation and Get Session
      this.convertQuizSessionToQuizData(sessionData)

      // Set timer
      this.timeDurationOnSecond = sessionData.quiz_overview.quiz_duration * 60
      const curDate = new Date()
      const startDate = new Date(
        sessionData.quiz_overview.quiz_session.start_time
      )
      this.timePassed = Math.floor(Math.abs(curDate - startDate) / 1000)

      // check time is up / quiz is finished
      if (!this.checkIfTimeIsUp()) {
        this.startTimer()
      } else {
        this.$router.push({
          name: checkAuthorizeRole('classroom-detail-task'),
          params: {
            id: this.classroomId,
            contentId: this.contentId,
            taskId: this.taskId,
          },
        })

        this.showToast('error', 'XIcon', 'Whoops', 'Waktu Sudah Habis')
      }

      this.isLoading = false
    })
  },

  methods: {
    // Filter Method
    checkAuthorizeRole,
    avatarText,
    taskType,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    // Quiz Navigations
    generateNavigationState() {
      const navigations = []
      for (let i = 0; i < this.allQuiz.length; i++) {
        navigations.push({
          question_id: this.allQuiz[i].question_id,
          question_number: i + 1,
          isAnswered: false,
          isMarked: false,
        })
      }
      this.quizNavigations = navigations
    },
    getQuizNavigationVariant(nav) {
      if (nav.question_number <= this.allQuiz.length) {
        // if current question

        if (
          nav.question_number === this.allQuiz[this.quizIndex].question_number
        ) {
          return 'primary'
        }

        // if marked or answered
        if (!nav.isMarked) {
          if (!nav.isAnswered) {
            return 'outline-primary'
          }
          return 'secondary'
        }
        return 'warning'
      }
      return 'outline-primary'
    },
    countAnswered() {
      let count = 0
      this.quizNavigations.forEach((nav) => {
        if (nav.isAnswered) {
          count += 1
        }
      })

      return count
    },
    checkIfAnswered() {
      const quiz = this.allQuiz[this.quizIndex]
      if (quiz.answer) {
        this.quizNavigations[this.quizIndex].isAnswered = true
      }
    },
    navigateToQuiz(index, paginate = false) {
      this.checkIfAnswered()
      // set answer for essay
      if (this.contentType === 'essay') {
        this.setAnswer(this.allQuiz[this.quizIndex].answer)
      }

      // navigate to quiz
      if (!paginate) {
        this.quizIndex = index
      } else {
        this.quizIndex += index
      }

      // load quiz answer to data
      this.answer = this.allQuiz[this.quizIndex].answer

      // set last viewed quiz
      this.setLastViewedQuestion()

      // saving quiz session
      this.setQuizSession()
    },
    setLastViewedQuestion() {
      if (this.quizIndex >= this.quizLastViewed) {
        this.quizLastViewed = this.quizIndex
      }
    },
    markQuiz() {
      this.quizNavigations[this.quizIndex].isMarked = !this.quizNavigations[
        this.quizIndex
      ].isMarked
    },
    createAnswer(optionId) {
      // save to all data and local view
      this.allQuiz[this.quizIndex].answer = optionId
      this.answer = optionId

      // set progress and navigation state
      this.checkIfAnswered()
      this.countIsAnswered = this.countAnswered()

      // save to session
      this.quizSession.data[this.quizIndex].exam_question_option_id = optionId
    },

    setAnswer(answer) {
      this.checkIfAnswered()
      this.countIsAnswered = this.countAnswered()

      // save to session
      this.quizSession.data[this.quizIndex].essay_answer = answer
    },

    // Quiz Submit Form
    submitForm() {
      if (this.contentType === 'essay') {
        this.setAnswer(this.allQuiz[this.quizIndex].answer)
      }

      this.setQuizSession()

      // show confirmation dialog
      this.$swal({
        title: 'Akhiri Kuis?',
        text: 'Anda tidak akan dapat mengubah jawaban setelah mengakhiri kuis',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.submitQuiz()
        }
      })
    },
    async submitQuiz() {
      this.isButtonLoading = true

      const sessionData = this.quizSession

      const data = new FormData()
      data.append('data', JSON.stringify(sessionData))
      data.append('_method', 'PUT')

      const url = `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/${this.taskId}/update-session?submit=true`

      await client.post(url, data).then((response) => {
        this.showToast(
          'success',
          'CheckIcon',
          'Berhasil',
          'Tugas berhasil dikumpulkan'
        )

        this.$router.push({
          name: checkAuthorizeRole('classroom-detail-task'),
          params: {
            id: this.classroomId,
            contentId: this.contentId,
            taskId: this.taskId,
          },
        })
      })
    },

    // Quiz Session
    // Set Session
    setQuizSession() {
      // save last viewed quiz to session
      this.quizSession.quiz_overview.quiz_last_viewed_index = this.quizLastViewed
      this.quizSession.quiz_overview.quiz_navigations = this.quizNavigations

      // set local session
      localStorage.setItem('quizSession', JSON.stringify(this.quizSession))

      // set server session
      this.saveServerSession(this.quizSession)
    },
    async saveServerSession(sessionData) {
      const data = new FormData()
      data.append('data', JSON.stringify(sessionData))
      data.append('_method', 'PUT')

      await client
        .post(
          `/students/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${this.taskId}/exams/${this.taskId}/update-session`,
          data
        )
        .then((response) => {
          console.log('session saved')
        })
    },

    // Get Session to Quiz Data
    convertQuizSessionToQuizData(session) {
      this.quizIndex = session.quiz_overview.quiz_last_viewed_index

      // Generate navigations state
      if (session.quiz_overview.quiz_navigations) {
        this.quizNavigations = session.quiz_overview.quiz_navigations
      } else {
        this.generateNavigationState()
      }

      // convert session to quiz data
      for (let i = 0; i < session.data.length; i += 1) {
        // set answer by type
        if (this.contentType === 'essay') {
          this.allQuiz[i].answer = session.data[i].essay_answer
        } else {
          this.allQuiz[i].answer = session.data[i].exam_question_option_id
        }

        this.answer = this.allQuiz[i].answer

        // set answer state on navigation if multiple choice
        if (session.data[i].exam_question_option_id) {
          this.quizNavigations[i].isAnswered = true
        }

        // set answer state on navigation if essay
        if (session.data[i].essay_answer) {
          this.quizNavigations[i].isAnswered = true
        }
      }

      // set quiz progress
      this.countIsAnswered = this.countAnswered()
    },

    // Timer
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    },
    onTimesUp() {
      this.setQuizSession()
      this.submitForm()
    },
    checkIfTimeIsUp() {
      let status = false
      if (this.timeDurationOnSecond < this.timePassed) {
        status = true
      }

      return status
    },
  },
}
</script>

<style scss>
.progress-quiz {
  width: 100%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.card-material-type {
  cursor: pointer;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}
</style>
